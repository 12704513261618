import { CommonEnvironment, CommonEnvironmentName } from '~ng-common-lib/core/tokens/environment';

export const environment: CommonEnvironment = {
	environmentName: CommonEnvironmentName.STAGING,

	server: {
		url: 'https://api.staging.rea.ch',
	},

	mainClient: {
		url: 'https://mobile.staging.rea.ch',
	},

	handbook: {
		url: 'https://handbook.staging.rea.ch',
	},

	myGenieServer: {
		url: 'https://chat.staging.rea.ch',
	},
};
